@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  font-family: "Izmir", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  scroll-behavior: smooth;
}

:root{
  --border-width: 0.1em;
  --edge-size: 0.5em;
  --background-color: #151515;
  --border-color:#2EBEEF;
}

.dark .button-bottom-right-corner-outline-lang{
  background: var(--border-color);
 
  clip-path: polygon(calc(100% - 0.5em) 100%, 100% calc(100% - 0.5em), 100% 0%, 0% 0%, 0% 100%);
}

.button-bottom-right-corner-outline-lang{
  background: var(--border-color);
  
  clip-path: polygon(calc(100% - 0.5em) 100%, 100% calc(100% - 0.5em), 100% 0%, 0% 0%, 0% 100%);
}

.dark .button-bottom-right-corner-outline-lang::after{
  content: "";
  position: absolute;
  inset: 0;
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: var(--background-color);
  z-index: -1;
  clip-path: polygon(calc(100% - 0.5em) 100%, 100% calc(100% - 0.5em), 100% 0%, 0% 0%, 0% 100%);
}

.button-bottom-right-corner-outline-lang::after{
  content: "";
  position: absolute;
  inset: 0;
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: #F5F8FA;
  z-index: -1;
  clip-path: polygon(calc(100% - 0.5em) 100%, 100% calc(100% - 0.5em), 100% 0%, 0% 0%, 0% 100%);
}

.dark .button-bottom-right-corner-outline{
  background: var(--border-color);
 
  clip-path: polygon(calc(100% - 0.5em) 100%, 100% calc(100% - 0.5em), 100% 0%, 0% 0%, 0% 100%);
}

.button-bottom-right-corner-outline{
  background: var(--border-color);
  
  clip-path: polygon(calc(100% - 0.5em) 100%, 100% calc(100% - 0.5em), 100% 0%, 0% 0%, 0% 100%);
}

.dark .button-bottom-right-corner-outline::after{
  content: "";
  position: absolute;
  inset: 0;
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: var(--background-color);
  z-index: -1;
  clip-path: polygon(calc(100% - 0.5em) 100%, 100% calc(100% - 0.5em), 100% 0%, 0% 0%, 0% 100%);
}

.button-bottom-right-corner-outline::after{
  content: "";
  position: absolute;
  inset: 0;
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: #F5F8FA;
  z-index: -1;
  clip-path: polygon(calc(100% - 0.5em) 100%, 100% calc(100% - 0.5em), 100% 0%, 0% 0%, 0% 100%);
}

.dark .button-bottom-right-corner-outline:hover::after{
  content: "";
  position: absolute;
  inset: 0;
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: var(--border-color);
  z-index: -1;
  clip-path: polygon(calc(100% - 0.5em) 100%, 100% calc(100% - 0.5em), 100% 0%, 0% 0%, 0% 100%);
  transition: all 0.3s ease-in-out;
}

.button-bottom-right-corner-outline:hover::after{
  content: "";
  position: absolute;
  inset: 0;
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: var(--border-color);
  z-index: -1;
  clip-path: polygon(calc(100% - 0.5em) 100%, 100% calc(100% - 0.5em), 100% 0%, 0% 0%, 0% 100%);
  transition: all 0.3s ease-in-out;
}



.button-bottom-left-corner-outline{
  background: var(--border-color);
  position: relative;
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0% 0%, 0% 0%, 0% calc(100% - .5em), .5em 100%);
}

.button-bottom-left-corner-outline::after{
  content: "";
  position: absolute;
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: #F5F8FA;
  z-index: -1;
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0% 0%, 0% 0%, 0% calc(100% - .5em), .5em 100%);
}

.dark .button-bottom-left-corner-outline::after{
  content: "";
  position: absolute;
  
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: var(--background-color);
  z-index: -1;
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0% 0%, 0% 0%, 0% calc(100% - .5em), .5em 100%);
}

.button-bottom-left-corner-outline:hover::after{
  content: "";
  position: absolute;
  transition: all 0.3s ease-in-out;
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: var(--border-color);
  z-index: -1;
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0% 0%, 0% 0%, 0% calc(100% - .5em), .5em 100%);
}

.dark .button-bottom-left-corner-outline:hover::after{
  content: "";
  position: absolute;
  transition: all 0.3s ease-in-out;
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: var(--border-color);
  z-index: -1;
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0% 0%, 0% 0%, 0% calc(100% - .5em), .5em 100%);
}



.button-bottom-left-corner{
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0% 0%, 0% 0%, 0% calc(100% - .5em), .5em 100%);
}

.button-bottom-right-corner{
  clip-path: polygon(calc(100% - 0.5em) 100%, 100% calc(100% - 0.5em), 100% 0%, 0% 0%, 0% 100%);
}

.button-top-left-corner{
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0.5em 0%, 0% 0.5em, 0% 100%, 0% 100%);

}

.button-top-left-corner-outline{
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0.5em 0%, 0% 0.5em, 0% 100%, 0% 100%);
  position:relative
}

.dark .button-top-left-corner-outline::after{
 content: "";
  position: absolute;
  
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: var(--background-color);
  z-index: -1;
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0.5em 0%, 0% 0.5em, 0% 100%, 0% 100%);
 
}
.button-top-left-corner-outline::after{
 content: "";
  position: absolute;
  
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: #F5F8FA;
  z-index: -1;
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0.5em 0%, 0% 0.5em, 0% 100%, 0% 100%);
 
}

.button-top-left-corner-outline:hover::after{
 content: "";
  position: absolute;
  transition: all 0.3s ease-in-out;
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: var(--border-color);
  z-index: -1;
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0.5em 0%, 0% 0.5em, 0% 100%, 0% 100%);
 
}

.dark .button-top-left-corner-outline:hover::after{
 content: "";
  position: absolute;
  transition: all 0.3s ease-in-out;
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: var(--border-color);
  z-index: -1;
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0.5em 0%, 0% 0.5em, 0% 100%, 0% 100%);
 
}

.timeline-corner{
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 2em 0%, 0% 2em, 0% 100%, 0% 100%);
  background: var(--border-color);
  position: relative;
}

.dark .timeline-corner::after{
  content: "";
  position: absolute;
  
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: var(--background-color);
  z-index: -1;
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 2em 0%, 0% 2em, 0% 100%, 0% 100%);
}

.timeline-corner::after{
  content: "";
  position: absolute;
  
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  background: #F5F8FA;
  z-index: -1;
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 2em 0%, 0% 2em, 0% 100%, 0% 100%);
}

.hero-text-corner{
  clip-path: polygon(calc(100% - 0.4em) 100%, 100% calc(100% - 0.4em), 100% 0%, 100% 0%, 0% 0%, 0% 0%, 0% 100%, 0% 100%);

}

.privileges-bottom-left-corner{
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0% 0%, 0% 0%, 0% calc(100% - 5em), 5em 100%);
  position:relative;
}

.privileges-bottom-left-corner::after{
  content:"";
  inset:0;
  position:absolute;
  top:0.1em;
  left:0.1em;
  right:0.1em;
  bottom:0.1em;
  z-index:-1;
 
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 0% 0%, 0% 0%, 0% calc(100% - 5em), 5em 100%);
}

.privileges-bottom-right-corner{
  position:relative;
  clip-path: polygon(calc(100% - 5em) 100%, 100% calc(100% - 5em), 100% 0%, 0% 0%, 0% 100%);
}

.privileges-top-left-corner{
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 5em 0%, 0% 5em, 0% 100%, 0% 100%);

}

.privileges-top-right-corner{
  clip-path: polygon(100% 100%, 100% 100%, 100% 5em, calc(100% - 5em) 0%, 0% 0%, 0% 0%, 0% 100%, 0% 100%);

}

.privileges-bottom-right-corner::after{
  content:"";
  inset:0;
  position:absolute;
  top:0.1em;
  left:0.1em;
  right:0.1em;
  bottom:0.1em;
  z-index:-1;
  clip-path: polygon(calc(100% - 5em) 100%, 100% calc(100% - 5em), 100% 0%, 0% 0%, 0% 100%);
}

.privileges-top-left-corner::after{
  content:"";
  inset:0;
  position:absolute;
  top:0.1em;
  left:0.1em;
  right:0.1em;
  bottom:0.1em;
  z-index:-1;
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 5em 0%, 0% 5em, 0% 100%, 0% 100%);

}

.privileges-top-right-corner::after{
  content:"";
  inset:0;
  position:absolute;
  top:0.1em;
  left:0.1em;
  right:0.1em;
  bottom:0.1em;
  z-index:-1;
  clip-path: polygon(100% 100%, 100% 100%, 100% 5em, calc(100% - 5em) 0%, 0% 0%, 0% 0%, 0% 100%, 0% 100%);

}

.tokenomics-top-left-corner::after{
  content:"";
  inset:0;
  position:absolute;
  top:0.1em;
  left:0.1em;
  right:0.1em;
  bottom:0.1em;
  z-index:-1;
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 1em 0%, 0% 1em, 0% 100%, 0% 100%);

}

.tokenomics-top-right-corner{
  clip-path: polygon(100% 100%, 100% 100%, 100% 1em, calc(100% - 1em) 0%, 0% 0%, 0% 0%, 0% 100%, 0% 100%);

}

.tokenomics-bottom-left-right-corner{
  clip-path: polygon(calc(100% - 1em) 100%, 100% calc(100% - 1em), 100% 0%, 100% 0%, 0% 0%, 0% 0%, 0% calc(100% - 1em), 1em 100%);

}

.roadmap-bottom-right-corner{
  clip-path: polygon(calc(100% - 2em) 100%, 100% calc(100% - 2em), 100% 0%, 0% 0%, 0% 100%);
}

.roadmap-left-corner{
  clip-path: polygon(100% 100%, 100% 100%, 100% 0%, 100% 0%, 2em 0%, 0% 2em, 0% calc(100% - 2em), 2em 100%)
}

.roadmap-right-corner{
  clip-path: polygon(calc(100% - 2em) 100%, 100% calc(100% - 2em), 100% 2em, calc(100% - 2em) 0%, 0% 0%, 0% 0%, 0% 100%, 0% 100%);
}

.glow{
  filter: drop-shadow(0px 0px 10px rgba(46, 190, 239, 0.35));
}

body.dark {
  background: linear-gradient(59deg, #151515 -0.6%, #000 97.41%);
}

body {
  background: linear-gradient(59deg, #f5f8fa -0.6%, #f5f8fa 97.41%);
  overflow-x: hidden;
}

.button-shadow {
  filter: drop-shadow(4px 4px 30px rgba(46, 190, 239, 0.35));
}


.izmr-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
}

.tokenomi-graph {
  background: linear-gradient(
    180deg,
    #2ebeef 0%,
    rgba(46, 190, 239, 0.25) 100%
  );
}

.tokenomi-shadow {
  box-shadow: 2px, 4px, 15%, rgba(46, 190, 239, 35%);
}

@font-face {
  font-family: "Izmir";
  src: url("../public/assets/fonts/Izmir-Regular.otf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Izmir";
  src: url("../public/assets/fonts/Izmir-Medium.otf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "Izmir";
  src: url("../public/assets/fonts/Izmir-Semibold.otf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "Izmir";
  src: url("../public/assets/fonts/Izmir-Bold.otf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Izmir";
  src: url("../public/assets/fonts/Izmir-Extrabold.otf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: "Izmir";
  src: url("../public/assets/fonts/Izmir-Bold-Italic.otf") format("truetype");
  font-style: italic;
  font-display: swap;
  font-weight: 700;
}
